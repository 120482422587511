/* eslint no-console: 0 */
/* eslint no-param-reassign: 0 */
/* eslint no-shadow: 0 */
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import BerrySupportOrdersApi from '../../api/berrysupportOrder';

const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isUpdating: false,
    isError: false,
  },
};

export const getters = {
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getOrders: $state => {
    return $state.records;
  },
};

export const actions = {
  get: async ({ commit }, { email, phone_number, contactId, refresh }) => {
    commit(types.default.SET_BERRY_SUPPORT_ORDERS_UI_FLAG, {
      isFetching: true,
      isError: false,
    });
    try {
      const response = await BerrySupportOrdersApi.getOrders({
        email,
        phone_number,
        contactId,
        refresh,
      });
      commit(types.default.SET_BERRY_SUPPORT_ORDERS, response.data);
      commit(types.default.SET_BERRY_SUPPORT_ORDERS_UI_FLAG, {
        isFetching: false,
        isError: false,
      });
    } catch (error) {
      commit(types.default.SET_BERRY_SUPPORT_ORDERS_UI_FLAG, {
        isFetching: false,
        isError: true,
      });
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.default.SET_BERRY_SUPPORT_ORDERS_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_BERRY_SUPPORT_ORDERS]: MutationHelpers.set,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
