/* global axios */
import ShopApiClient from './ShopApiClient';

class BerrySupportCacheApi extends ShopApiClient {
  constructor() {
    super('cache', { accountScoped: false, apiVersion: 'v1' });
  }

  delete(resource) {
    return axios.delete(this.url, {
      data: {
        resource,
      },
    });
  }
}

export default new BerrySupportCacheApi();
