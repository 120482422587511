/* eslint arrow-body-style: 0 */
import ShopAuthenticate from './components/ShopAuthenticate';
import { frontendURL } from '../../../helper/URLHelper';

export const routes = [
  {
    path: frontendURL('shop_authenticate'),
    name: 'shop_authenticate',
    roles: ['administrator', 'agent'],
    component: ShopAuthenticate,
  },
];
