/* eslint no-console: 0 */
/* eslint no-param-reassign: 0 */
/* eslint no-shadow: 0 */
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import BerrySupportChargeApi from '../../api/berrysupportCharge';

const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
    isFetchingError: false,
    isCreatingError: false,
    isDeletingError: false,
    isUpdatingError: false,
  },
};

export const getters = {
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getCharges: $state => {
    return $state.records;
  },
};

export const actions = {
  show: async ({ commit }, { id }) => {
    commit(types.default.SET_BERRY_SUPPORT_CHARGES_UI_FLAG, {
      isFetching: true,
      isFetchingError: false,
    });
    try {
      const response = await BerrySupportChargeApi.show(id);
      commit(types.default.SET_BERRY_SUPPORT_CHARGES, response.data);
      commit(types.default.SET_BERRY_SUPPORT_CHARGES_UI_FLAG, {
        isFetching: false,
        isFetchingError: false,
      });
    } catch (error) {
      commit(types.default.SET_BERRY_SUPPORT_CHARGES_UI_FLAG, {
        isFetching: false,
        isFetchingError: true,
      });
      throw new Error(error);
    }
  },
  create: async function createCharge({ commit }, { accountId = '' }) {
    commit(types.default.SET_BERRY_SUPPORT_CHARGES_UI_FLAG, {
      isCreating: true,
      isCreatingError: false,
    });
    try {
      const response = await BerrySupportChargeApi.create({ accountId });
      commit(types.default.ADD_BERRY_SUPPORT_CHARGES, response.data);
      commit(types.default.SET_BERRY_SUPPORT_CHARGES_UI_FLAG, {
        isCreating: false,
        isCreatingError: false,
      });
      window.location.replace(response.data.confirmation_url);
      return response.data;
    } catch (error) {
      commit(types.default.SET_BERRY_SUPPORT_CHARGES_UI_FLAG, {
        isCreating: false,
        isCreatingError: true,
      });
      throw new Error(error);
    } finally {
      commit(types.default.SET_BERRY_SUPPORT_CHARGES_UI_FLAG, {
        isCreating: false,
      });
    }
  },
  update: async function updateCharge({ commit }, { id, ...updateObj }) {
    commit(types.default.SET_BERRY_SUPPORT_CHARGES_UI_FLAG, {
      isUpdating: true,
      isUpdatingError: false,
    });
    try {
      const response = await BerrySupportChargeApi.update(id, updateObj);
      commit(types.default.UPDATE_BERRY_SUPPORT_CHARGES, response.data);
      commit(types.default.SET_BERRY_SUPPORT_CHARGES_UI_FLAG, {
        isUpdating: false,
        isUpdatingError: false,
      });
      return response.data;
    } catch (error) {
      commit(types.default.SET_BERRY_SUPPORT_CHARGES_UI_FLAG, {
        isUpdating: false,
        isUpdatingError: true,
      });
      throw new Error(error);
    } finally {
      commit(types.default.SET_BERRY_SUPPORT_CHARGES_UI_FLAG, {
        isUpdating: false,
      });
    }
  },
  delete: async function deleteScriptTag({ commit }, { chargeId = '' }) {
    commit(types.default.SET_BERRY_SUPPORT_CHARGES_UI_FLAG, {
      isDeleting: true,
      isDeletingError: false,
    });
    try {
      await BerrySupportChargeApi.delete(chargeId);
      commit(types.default.DELETE_BERRY_SUPPORT_CHARGES, chargeId);
    } catch (error) {
      commit(types.default.SET_BERRY_SUPPORT_CHARGES_UI_FLAG, {
        isDeleting: false,
        isDeletingError: true,
      });
      throw new Error(error);
    } finally {
      commit(types.default.SET_BERRY_SUPPORT_CHARGES_UI_FLAG, {
        isDeleting: false,
        isDeletingError: false,
      });
    }
  },
};

export const mutations = {
  [types.default.SET_BERRY_SUPPORT_CHARGES_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_BERRY_SUPPORT_CHARGES]: MutationHelpers.set,
  [types.default.ADD_BERRY_SUPPORT_CHARGES]: MutationHelpers.create,
  [types.default.EDIT_BERRY_SUPPORT_CHARGES]: MutationHelpers.update,
  [types.default.DELETE_BERRY_SUPPORT_CHARGES]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
