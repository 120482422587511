<template>
  <div class="wizard-body small-9 columns shop-authenticate-wrapper">
    <div class="column">
      <div class="auth-error-img-wrapper">
        <img
          src="~dashboard/assets/images/flag.svg"
          alt="Shop Authentication Error"
        />
      </div>
      <h2 class="page-sub-title">
        Shopify Authentication Error
      </h2>
      <p class="small-12 column">
        Your authentication and cookies to access your Shopify store data has
        expired.
      </p>
    </div>
    <br />
    <form class="row" @submit.prevent="authenticateStore">
      <div class="medium-12 columns">
        <label>
          Shopify Store ID (eg: yourstore.myshopify.com)
          <input
            v-model="storeDomain"
            type="text"
            placeholder="eg: mystore.myshopify.com"
        /></label>
      </div>

      <div class="modal-footer">
        <div class="medium-12 columns">
          <button
            type="submit"
            data-testid="submit_button"
            :disabled="!storeDomain"
            class="button nice "
          >
            <!---->
            <span>Authenticate Via Shopify</span>
            <!---->
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Cookie from 'js-cookie';

export default {
  data() {
    return {
      storeDomain: '',
    };
  },
  mounted() {
    const accessToken = Cookie.get('berrysupportshopaccesstoken');
    const shopName = Cookie.get('berrysupportshopname');
    if (accessToken && shopName) {
      this.$router.replace('/app/accounts/');
    }
  },
  methods: {
    authenticateStore() {
      window.location.href = `${window.chatwootConfig.shopApiURL}/shopify?shop=${this.storeDomain}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

div.shop-authenticate-wrapper {
  margin: 0 auto;

  & .auth-error-img-wrapper {
    text-align: center;
    & img {
      margin: $space-normal;
      width: 20rem;
    }
  }
}
</style>
