/* global axios */
import ShopApiClient from './ShopApiClient';

class BerrySupportChargesApi extends ShopApiClient {
  constructor() {
    super('charges', { accountScoped: false, apiVersion: 'v1' });
  }

  getCharges({ accountId }) {
    if (accountId) {
      return axios.get(`${this.url}`, {
        params: {
          accountId,
        },
      });
    }
    return new Promise((res, rej) => {
      rej(
        new Error('Account ID is missing. Please logout and the login again.')
      );
    });
  }
}

export default new BerrySupportChargesApi();
