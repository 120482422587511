/* eslint arrow-body-style: 0 */
import BerrySupportShopCharges from './components/BerrySupportShopCharges';
import { frontendURL } from '../../../helper/URLHelper';

export const routes = [
  {
    path: frontendURL('accounts/:accountId/plans'),
    name: 'shop_plans',
    roles: ['administrator', 'agent'],
    component: BerrySupportShopCharges,
    props: {
      icon: 'ion-card',
    },
  },
];
