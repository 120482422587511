import AppContainer from './Dashboard';
import settings from './settings/settings.routes';
import conversation from './conversation/conversation.routes';
import { routes as contactRoutes } from './contacts/routes';
import { routes as notificationRoutes } from './notifications/routes';
import { frontendURL } from '../../helper/URLHelper';
import { routes as berrySupportShopAuthenticateRoutes } from './berrysupportshopauth/routes';
import { routes as berrySupportShopPlanRoutes } from './berrysupportshopplan/routes';
import Cookie from 'js-cookie';
import helpcenterRoutes from './helpcenter/helpcenter.routes';

const Suspended = () => import('./suspended/Index');

export default {
  routes: [
    ...berrySupportShopAuthenticateRoutes,
    ...helpcenterRoutes.routes,
    {
      path: frontendURL('accounts/:account_id'),
      component: AppContainer,
      children: [
        ...conversation.routes,
        ...settings.routes,
        ...contactRoutes,
        ...notificationRoutes,
        ...berrySupportShopPlanRoutes,
      ],
      beforeEnter(to, from, next) {
        const accessToken = Cookie.get('berrysupportshopaccesstoken');
        const shopName = Cookie.get('berrysupportshopname');
        if (!accessToken || !shopName) {
          next({
            name: 'shop_authenticate',
          });
        } else {
          next();
        }
      },
    },
    {
      path: frontendURL('accounts/:accountId/suspended'),
      name: 'account_suspended',
      roles: ['administrator', 'agent'],
      component: Suspended,
    },
  ],
};
