/* eslint-disable */
<template>
  <div class="view-box columns bg-light">
    <div class="settings-header">
      <h1 class="page-title">
        <woot-sidemenu-icon />
        <back-button v-if="showBackButton" :back-url="backUrl" />
        <i :class="iconClass">&nbsp;</i>
        <span>{{ $t('PLANS_MGMT.HEADER') }}</span>
      </h1>
    </div>
    <div class="column content-box">
      <woot-loading-state
        v-if="uiFlags.isFetching"
        :message="loadingIndicatorMessage"
      />
      <div
        v-if="uiFlags.isFetchingError && !uiFlags.isFetching"
        class="columns full-height charges-empty-state"
      >
        <img
          src="~dashboard/assets/images/inboxes.svg"
          alt="No Inboxes"
          width="120"
        />
        <br />
        <p>Could not get fetch your charges. Please try again</p>
        <button
          class="button success nice"
          :disabled="uiFlags.isFetching"
          @click="getCharges()"
        >
          <i v-if="uiFlags.isFetching" class="icon" />
          <spinner v-if="uiFlags.isFetching" />
          Try Again
        </button>
      </div>
      <div class="row">
        <div class="column">
          <div v-for="charge in charges" :key="charge.id">
            <div
              v-if="charge.status === 'accepted'"
              class="charge-banner-wrapper row"
            >
              <div class="columns medium-8">
                <h5>
                  <b
                    >Your {{ charge.name }} plan costing {{ charge.price }} is
                    still PENDING</b
                  >
                </h5>
                <p>
                  You have paid for and <b>accepted</b> the plan but the
                  <b>activation</b> is still <b>pending</b>. Please activate
                  your plan by pressing the <b>Activate Plan</b> button below.
                </p>
              </div>
              <button
                class="button success nice"
                :disabled="uiFlags.isUpdating"
                @click="patchCharge({ chargeId: charge.id })"
              >
                <i v-if="uiFlags.isUpdating" class="icon" />
                <spinner v-if="uiFlags.isUpdating" />
                Activate Plan
              </button>
            </div>
          </div>

          <div class="plan-wrapper row">
            <div class="plan-info-wrapper columns medium-8">
              <div class="plan-summary-wrapper">
                <h3><b>Paid Unlimited Plan</b></h3>
                <p>
                  Please contact us at
                  <a href="mailto:info@getfocalsoft.com">info@getfocalsoft.com</a>
                  or the Live Chat via this dashboard.
                </p>
              </div>
              <div class="plan-features-wrapper">
                <div class="plan-features-heading-wrapper">
                  <a href="#"><b>WHATS INCLUDED</b></a>
                  <hr />
                </div>
                <ul class="plan-features-list-wrapper">
                  <li>
                    <i class="icon ion-checkmark">&nbsp;</i>
                    Unlimited Everything
                  </li>
                  <li>
                    <i class="icon ion-checkmark">&nbsp;</i>
                    Shopify Integration
                  </li>
                  <li>
                    <i class="icon ion-checkmark">&nbsp;</i>
                    Unlimited conversations
                  </li>
                  <li>
                    <i class="icon ion-checkmark">&nbsp;</i>
                    Auto assignment
                  </li>
                  <li>
                    <i class="icon ion-checkmark">&nbsp;</i>
                    Canned Quick Responses
                  </li>
                  <li>
                    <i class="icon ion-checkmark">&nbsp;</i>
                    Conversation Labels
                  </li>
                  <li>
                    <i class="icon ion-checkmark">&nbsp;</i>
                    Private Notes
                  </li>
                  <li>
                    <i class="icon ion-checkmark">&nbsp;</i>
                    Facebook Social Channel
                  </li>
                  <li>
                    <i class="icon ion-checkmark">&nbsp;</i>
                    Instagram Social Channel
                  </li>
                  <li>
                    <i class="icon ion-checkmark">&nbsp;</i>
                    Twilio Whatsapp & SMS Channel
                  </li>
                  <li>
                    <i class="icon ion-checkmark">&nbsp;</i>
                    Website widget
                  </li>
                  <li>
                    <i class="icon ion-checkmark">&nbsp;</i>
                    Widget customization
                  </li>
                  <li>
                    <i class="icon ion-checkmark">&nbsp;</i>
                    Business Hours
                  </li>
                  <li>
                    <i class="icon ion-checkmark">&nbsp;</i>
                    Chatbots
                  </li>
                  <li>
                    <i class="icon ion-checkmark">&nbsp;</i>
                    Twitter DMs & Tweets Channel
                  </li>
                  <li>
                    <i class="icon ion-checkmark">&nbsp;</i>
                    Webhooks
                  </li>
                  <li>
                    <i class="icon ion-checkmark">&nbsp;</i>
                    Teams
                  </li>
                  <li>
                    <i class="icon ion-checkmark">&nbsp;</i>
                    Priority Support
                  </li>
                </ul>
              </div>
            </div>
            <div class="plan-price-wrapper columns">
              <p>Introductory Offer Price</p>
              <h1><b>$24.99/mo</b></h1>
              <p class="plan-price-strike">
                <strike>$49.99/mo</strike>
              </p>
              <button
                class="button success block nice"
                :disabled="uiFlags.isCreating || uiFlags.isFetching"
                @click="handleChargeCTA()"
              >
                <i
                  v-if="uiFlags.isCreating || uiFlags.isFetching"
                  class="icon ion-spinner"
                />
                <spinner v-if="uiFlags.isCreating" />
                {{ getPlanCTALabel }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import configMixin from 'shared/mixins/configMixin';
import Spinner from 'shared/components/Spinner';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    Spinner,
  },
  mixins: [configMixin, alertMixin],
  props: {
    showBackButton: { type: Boolean, default: false },
    icon: {
      default: '',
      type: String,
    },
    backUrl: {
      type: [String, Object],
      default: '',
    },
    headerTitle: {
      default: '',
      type: String,
    },
  },
  data() {
    return {};
  },
  created() {
    // const { charge_id } = this.$route.query;
    // if (charge_id) {
    //   this.patchCharge({ chargeId: charge_id });
    // }
  },
  methods: {
    async deleteCharge({ chargeId }) {
      try {
        /* eslint-disable no-unused-expressions */
        await this.$store.dispatch('berrysupportCharges/delete', { chargeId });
        this.showAlert('Unsubscribed & Deleted successfully');
        this.getCharges();
      } catch (e) {
        this.showAlert(e.message);
      }
    },
    async createCharge() {
      try {
        /* eslint-disable no-unused-expressions */
        await this.$store.dispatch('berrysupportCharges/create', {
          accountId: this.accountId,
        });
        return true;
      } catch (e) {
        this.showAlert(e.message);
      }
      return true;
    },
    async patchCharge({ chargeId }) {
      try {
        this.$store.dispatch('berrysupportCharges/update', {
          id: this.accountId,
          chargeId,
        });
        this.showAlert('Your plan has been successfully activated');
        this.getCharges();
      } catch (e) {
        this.getCharges();
        this.showAlert(e.message); // True
      }
      return true;
    },
    getCharges() {
      this.$store.dispatch('berrysupportCharges/show', { id: this.accountId });
    },
    handleChargeCTA() {
      if (this.charges && this.charges.length) {
        const activeCharge = this.charges.find(
          charge => charge.status === 'active'
        );
        if (activeCharge) {
          this.deleteCharge({ chargeId: activeCharge.id });
        } else {
          this.createCharge();
        }
      } else {
        this.createCharge();
      }
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      uiFlags: 'berrysupportCharges/getUIFlags',
      chargesList: 'berrysupportCharges/getCharges',
      accountId: 'getCurrentAccountId',
    }),
    charges() {
      return this.chargesList;
    },
    loadingIndicatorMessage() {
      if (this.uiFlags.isFetching) {
        return 'Loading charges...';
      }
      return '';
    },
    iconClass() {
      return `icon ${this.icon} header--icon`;
    },
    getPlanCTALabel() {
      if (this.charges && this.charges.length) {
        if (this.charges.find(charge => charge.status === 'active')) {
          return 'Cancel Plan';
        }
        return 'Buy Now';
      }
      return 'Buy Now';
    },
  },
  mounted() {
    setTimeout(() => {
      this.getCharges();
    }, 500);
  },
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    if (this.charges) {
      const active = this.charges.find(charge => charge.status === 'active');
      const trial = this.charges.find(
        charge => charge.status === 'berrysupporttrial'
      );
      if (active || trial || (!active && !trial)) {
        // The second condition here is cos of network or api faiulre of shop.berrysupport.com
        next();
      } else {
        this.showAlert(
          'You trial is over. Please subscribe to our PAID UNLIMITED plan to continue.'
        );
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.charges-empty-state {
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.charge-banner-wrapper {
  margin: $space-normal;
  padding: $space-normal;
  border: 1px solid $color-border;
  background: white;
  border-radius: var(--border-radius-small);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plan-wrapper {
  display: flex;
  flex-direction: row;
  margin: $space-normal;
  background: white;
  border-radius: var(--border-radius-small);
  border: 1px solid $color-border;

  & > .columns {
    padding: $space-normal;
    & .plan-features-wrapper {
      & .plan-features-heading-wrapper {
        display: flex;
        align-items: center;
        & hr {
          border-color: $color-border;
          width: 77%;
        }
      }
      & ul.plan-features-list-wrapper {
        list-style-type: none;
        font-size: $font-size-default;
        margin-left: 0;

        & li {
          float: left;
          width: 45%;
          margin-right: 5%;
          margin-top: 1rem;
        }

        & i.icon {
          color: $success-color;
          margin-right: $space-small;
        }
      }
    }
  }

  & > .plan-price-wrapper {
    background: $color-background;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $space-normal;
    flex-direction: column;

    & p.plan-price-strike {
      font-size: $font-size-large;
    }

    & p {
      font-weight: bold;
    }
  }
}
</style>
