<template>
  <div>
    <woot-loading-state
      v-if="uiFlags.isFetching"
      :message="loadingIndicatorMessage"
    />
    <div
      v-if="uiFlags.isError && !uiFlags.isFetching"
      class="berrysupport-orders-failure"
    >
      <a href="#" @click="getBerrySupportOrders($event)">
        🔁 &nbsp; Error fetching orders.
        <br />
        Try again
      </a>
    </div>
    <div v-if="!uiFlags.isFetching && !uiFlags.isError">
      <div class="berrysupport-orders-count">
        <div>
          <div>
            Total orders: <b>{{ (orders && orders.length) || 0 }}</b>
          </div>
          <div>
            Total spent:
            <b v-if="orders && orders.length">
              {{
                orders.reduce(
                  (p, order) => p + parseFloat(order.total_price, 10),
                  0
                )
              }}
            </b>
          </div>
        </div>
        <button
          class="button clear small edit-button"
          @click="getBerrySupportOrders($event)"
        >
          <!-- <i title="Refresh"  class="ion-ios-refresh"></i> -->
          Refresh
        </button>
      </div>
      <div v-if="orders.length" class="berrysupport-orders-panel">
        <div v-for="order in orders" :key="order.order_number">
          <berry-support-accordion
            :title="'Order# ' + order.order_number + ' | ' + order.total_price"
          >
            <div class="label-wrap order-action-label-wrapper">
              <div class="label">
                <a
                  :href="getEditOrderLink({ orderId: order.id })"
                  target="_blank"
                >
                  Edit Order
                </a>
              </div>
              <div class="label">
                <a :href="getRefundLink({ orderId: order.id })" target="_blank">
                  Refund
                </a>
              </div>
              <div class="label">
                <a
                  :href="getShopifyAdminOrderPageLink({ orderId: order.id })"
                  target="_blank"
                >
                  More
                </a>
              </div>
            </div>
            <div>
              <span class="order-detail-light">Created on: </span>
              <span class="order-detail-dark">{{
                format(new Date(order.created_at), 'MM-dd-yyyy')
              }}</span>
            </div>
            <div>
              <span class="order-detail-light">Updated on: </span>
              <span class="order-detail-dark">{{
                format(new Date(order.updated_at), 'MM-dd-yyyy')
              }}</span>
            </div>
            <div>
              <span class="order-detail-light">Total: </span>
              <span class="order-detail-dark">{{
                order.currency + ' ' + order.total_price
              }}</span>
            </div>
            <div>
              <span class="order-detail-light">Phone: </span>
              <span class="order-detail-dark">{{ order.phone }}</span>
            </div>
            <div>
              <span class="order-detail-light">Email: </span>
              <span class="order-detail-dark">{{ order.email }}</span>
            </div>
            <hr />

            <!-- Order statuses start -->
            <div>
              <div class="label-wrap berrysupport-order-details-heading">
                <div class="label">
                  <span>Order statuses</span>
                </div>
              </div>
              <div class="label-wrap order-status-label-wrapper">
                <span class="order-detail-light">Financial: </span>
                <div class="label">
                  <span>{{ order.financial_status }}</span>
                </div>
              </div>
              <div
                v-if="order.fulfillment_status"
                class="label-wrap order-status-label-wrapper"
              >
                <span class="order-detail-light">Fulfillment: </span>
                <div class="label">
                  <span>{{ order.fulfillment_status }}</span>
                </div>
              </div>
              <div
                v-if="order.confirmed"
                class="label-wrap order-status-label-wrapper"
              >
                <span class="order-detail-light">Confirmation: </span>
                <div class="label">
                  <span>confirmed</span>
                </div>
              </div>
            </div>
            <hr />
            <!-- Order statuses end -->

            <!-- Shipping details end -->
            <div>
              <div class="label-wrap berrysupport-order-details-heading">
                <div class="label">
                  <span>Shipping address</span>
                </div>
              </div>
              <div>
                <span class="order-detail-light">Name: </span>
                <span class="order-detail-dark">{{
                  order.shipping_address.name
                }}</span>
              </div>
              <div>
                <span class="order-detail-light">City: </span>
                <span class="order-detail-dark">{{
                  order.shipping_address.city
                }}</span>
              </div>
              <div>
                <span class="order-detail-light">Country: </span>
                <span class="order-detail-dark">{{
                  order.shipping_address.province +
                    ', ' +
                    order.shipping_address.country
                }}</span>
              </div>
              <div>
                <span class="order-detail-light">Zip Code: </span>
                <span class="order-detail-dark">{{
                  order.shipping_address.zip
                }}</span>
              </div>
              <div>
                <span class="order-detail-light">Phone: </span>
                <span class="order-detail-dark">{{
                  order.shipping_address.phone
                }}</span>
              </div>
            </div>
            <hr />
            <!-- Shipping details end -->

            <!-- Line items in order start -->
            <div>
              <div class="label-wrap berrysupport-order-details-heading">
                <div class="label">
                  <span>Order details</span>
                </div>
              </div>
              <div v-for="line_item in order.line_items" :key="line_item.id">
                <div>
                  <span class="order-detail-light">Item: </span>
                  <span class="order-detail-dark">{{ line_item.name }}</span>
                </div>
                <div>
                  <span class="order-detail-light">Quantiy: </span>
                  <span class="order-detail-dark">{{
                    line_item.quantity
                  }}</span>
                </div>
                <div>
                  <span class="order-detail-light">SKU: </span>
                  <span class="order-detail-dark">{{ line_item.sku }}</span>
                </div>
                <div>
                  <span class="order-detail-light">Discount applied: </span>
                  <span class="order-detail-dark">{{
                    line_item.total_discount
                  }}</span>
                </div>
                <div>
                  <span class="order-detail-light">Price: </span>
                  <span class="order-detail-dark">{{ line_item.price }}</span>
                </div>
                <hr />
              </div>
            </div>
            <hr v-if="!order.line_items.length" />
            <!-- Line items in order end -->

            <!-- Discount codes used start -->
            <div>
              <div class="label-wrap berrysupport-order-details-heading">
                <div class="label">
                  <span>Discounts applied</span>
                </div>
              </div>
              <div
                v-for="(discount, index) in order.discount_applications"
                :key="index"
              >
                <div>
                  <span class="order-detail-light">Code: </span>
                  <span class="order-detail-dark">{{ discount.code }}</span>
                </div>
                <div>
                  <span class="order-detail-light">Value: </span>
                  <span class="order-detail-dark">{{ discount.value }}</span>
                </div>
                <div>
                  <span class="order-detail-light">Type: </span>
                  <span class="order-detail-dark">{{
                    discount.value_type
                  }}</span>
                </div>
              </div>
            </div>
            <hr />
            <!-- Discount codes used end -->

            <!-- Order Status URL start -->
            <div>
              <div class="label-wrap berrysupport-order-details-heading">
                <div class="label">
                  <span>Order status page</span>
                </div>
              </div>
              <div>
                <a :href="order.order_status_url" target="_blank">Visit page</a>
              </div>
            </div>
            <hr />
            <!-- Order Status URL END -->

            <!-- ORder notes start -->
            <div>
              <div class="label-wrap berrysupport-order-details-heading">
                <div class="label">
                  <span>Order notes & attributes</span>
                </div>
              </div>
              <div>
                <span class="order-detail-dark">{{ order.note }}</span>
              </div>
              <div class="label-wrap">
                <ul v-for="(note, index) in order.note_attributes" :key="index">
                  <li>{{ note.name }}: {{ note.value }}</li>
                </ul>
              </div>
            </div>
            <hr />
            <!-- Order notes end -->

            <!-- Order tags start -->
            <div>
              <div class="label-wrap berrysupport-order-details-heading">
                <div class="label">
                  <span>Order tags</span>
                </div>
              </div>
              <div class="label-wrap berrysupport-order-tags-wrapper">
                <div
                  v-for="(tag, index) in order.tags.split(',')"
                  :key="index"
                  class="label"
                >
                  <span>{{ tag.trim() }}</span>
                </div>
              </div>
            </div>
            <hr />
            <!-- Order tags end -->

            <!-- Order referring site start -->
            <div>
              <div class="label-wrap berrysupport-order-details-heading">
                <div class="label">
                  <span>Order referring site</span>
                </div>
              </div>
              <div>
                <a :href="order.referring_site" target="_blank">{{
                  order.referring_site
                }}</a>
              </div>
            </div>
            <hr />
            <!-- Order referring site end -->
          </berry-support-accordion>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BerrySupportAccordion from 'dashboard/components/widgets/berrysupport/BerrySupportAccordion.vue';
import format from 'date-fns/format';
import Cookies from 'js-cookie';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    BerrySupportAccordion,
  },
  mixins: [alertMixin],
  props: {
    contact: {
      type: [Object],
    },
    contactId: {
      type: [String, Number],
      required: true,
    },
  },
  watch: {
    $route() {
      this.getBerrySupportOrders();
    },
  },
  mounted() {
    this.getBerrySupportOrders();
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      getOrders: 'berrysupportOrders/getOrders',
      uiFlags: 'berrysupportOrders/getUIFlags',
    }),
    orders() {
      return this.getOrders;
    },
    loadingIndicatorMessage() {
      if (this.uiFlags.isFetching) {
        // return this.$t('CONVERSATION.LOADING_INBOXES');
        return 'Loading orders...';
      }
      return '';
      // return this.$t('CONVERSATION.LOADING_CONVERSATIONS');
    },
  },
  methods: {
    async getBerrySupportOrders(event) {
      try {
        await this.$store.dispatch('berrysupportOrders/get', {
          email: this.contact.email,
          phone_number: this.contact.phone_number,
          contactId: this.contactId,
          refresh: !!event,
        });
      } catch (e) {
        if (event) {
          this.showAlert(e.message);
        }
      }
    },
    format: format,
    getRefundLink({ orderId }) {
      return `http://${Cookies.get(
        'berrysupportshopname'
      )}/admin/orders/${orderId}/refund`;
    },
    getEditOrderLink({ orderId }) {
      return `http://${Cookies.get(
        'berrysupportshopname'
      )}/admin/orders/${orderId}/edit`;
    },
    getShopifyAdminOrderPageLink({ orderId }) {
      return `http://${Cookies.get(
        'berrysupportshopname'
      )}/admin/orders/${orderId}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.berrysupport-orders-failure {
  padding: var(--space-slab);
  text-align: center;
  & a {
  }
}
.berrysupport-orders-count {
  font-size: $font-size-small;
  color: var(--color-heading);
  padding: var(--space-slab);
  display: flex;
  justify-content: space-between;
  align-items: center;

  & i {
    font-size: 2em;
    color: $color-woot;
  }
}
.berrysupport-orders-panel {
  margin: 0 var(--space-slab);
  border: 1px solid var(--color-border);
  border-radius: 5px;
  & hr {
    border: 1px solid $color-border-light;
    margin: $space-slab auto;
  }
  & div.order-action-label-wrapper .label {
    padding-left: 0;
  }
  & div.label-wrap {
    & div.label {
      background: transparent;
    }
  }
  & div.label-wrap.order-status-label-wrapper {
    & div.label {
      background-color: var(--color-border-dark);
      color: var(--color-body);
    }
  }
  & .berrysupport-order-details-heading {
    & div.label {
      padding-left: 0;
      color: var(--color-heading);
      font-weight: bold;
      font-size: $font-size-small;
    }
  }
  & .order-detail-light,
  .order-detail-dark {
    font-size: $font-size-small;
  }
  & .order-detail-light {
    color: $color-gray;
  }
  & .order-detail-dark {
    color: var(--color-heading);
  }
  & .berrysupport-order-tags-wrapper {
    & .label {
      font-size: $font-size-small;
      color: $color-white;
      background: $color-woot;
      margin: auto $space-small $space-small 0;
    }
  }
}
</style>
