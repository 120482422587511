<template>
  <modal :show.sync="show" :on-close="onClose" :close-on-backdrop-click="false">
    <woot-modal-header
      :header-title="$t('BERRYSUPPORT_ONBOARDING.HEADER')"
      :header-content="$t('BERRYSUPPORT_ONBOARDING.SUB_HEADER')"
    />
    <div
      v-for="(STEP, index) in $t('BERRYSUPPORT_ONBOARDING.STEPS')"
      :key="index"
    >
      <div
        v-show="showModuleStep === STEP.MODULE"
        class="berrysupport-onboard-wrapper"
      >
        <div class="berrysupport-onboard-step-wrapper">
          <div class="berrysupport-onboard-text-wrapper">
            <span>STEP {{ index + 1 }}</span>
            <h4>{{ STEP.TITLE }}</h4>
            <ul>
              <li v-for="(list, index) in STEP.LIST" :key="index">
                <i
                  class="icon ion-checkmark berrysupport-onboarding-feature-checkmark"
                >
                  &nbsp;
                </i>
                {{ list }}
              </li>
            </ul>
          </div>
          <div class="berrysupport-onboard-graphic-wrapper">
            <img :src="renderOnboardImage(STEP.MODULE)" />
          </div>
        </div>
        <div class="modal-footer">
          <div>
            <span />
            <button
              v-if="index > 0"
              class="button clear"
              @click="
                goToStep(
                  $t('BERRYSUPPORT_ONBOARDING.STEPS')[index - 1]['MODULE']
                )
              "
            >
              Previous
            </button>
            <!-- <button
              class="alert button clear"
              @click="goToStep($t('BERRYSUPPORT_ONBOARDING.STEPS')[index + 1]['MODULE'])"
              v-if="index < $t('BERRYSUPPORT_ONBOARDING.STEPS').length - 1"
            >
              {{ STEP.SKIP_BUTTON }}
            </button> -->
          </div>

          <div>
            <button
              v-if="index < $t('BERRYSUPPORT_ONBOARDING.STEPS').length - 1"
              class="button clear"
              @click="
                goToStep(
                  $t('BERRYSUPPORT_ONBOARDING.STEPS')[index + 1]['MODULE']
                )
              "
            >
              Next
              <span class="icon ion-android-forward">&nbsp;</span>
            </button>
            <router-link :to="getURL(STEP.MODULE)" target="_blank">
              <button class="button success">
                {{ STEP.SETUP_BUTTON }} &nbsp;
                <span class="icon ion-android-open">&nbsp;</span>
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import { mapGetters } from 'vuex';
import accountMixin from '../../../mixins/account';
import Modal from '../../Modal';

export default {
  components: {
    Modal,
  },
  mixins: [accountMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    onBerrySupportModalToggle: {
      type: Function,
      default: () => null,
    },
  },
  data: function() {
    return {
      showModuleStep: 'livechat',
      stepDone: false,
    };
  },
  computed: {
    ...mapGetters({ globalConfig: 'globalConfig/get' }),
    newInboxURL() {
      return this.addAccountScoping('settings/inboxes/new');
    },
    newAgentURL() {
      return this.addAccountScoping('settings/agents/list');
    },
    newLabelsURL() {
      return this.addAccountScoping('settings/labels/list');
    },
    newLiveChatURL() {
      return '';
    },
  },
  methods: {
    getURL(module) {
      switch (module) {
        case 'livechat':
          return this.addAccountScoping('settings/inboxes/new/website');
        case 'email':
          return this.addAccountScoping('settings/inboxes/new/email');
        case 'sms':
          return this.addAccountScoping(
            'settings/inboxes/new?highlight=sms,whatsapp,telegram'
          );
        case 'social':
          return this.addAccountScoping(
            'settings/inboxes/new?highlight=facebook,twitter,line'
          );
        case 'agents':
          return this.addAccountScoping('settings/agents/list');
        case 'teams':
          return this.addAccountScoping('settings/teams/list');
        case 'canned':
          return this.addAccountScoping('settings/canned-response/list');
        case 'notifications':
          return this.addAccountScoping('profile/settings');
        case 'campaigns':
          return this.addAccountScoping('campaigns');
        case 'completed':
          return this.addAccountScoping('dashboard');
        default:
          return this.addAccountScoping('settings/inboxes/');
      }
    },
    goToStep(module) {
      this.showModuleStep = module;
    },
    toggleStepDone() {
      this.stepDone = !this.stepDone;
    },
    onClose() {
      this.onBerrySupportModalToggle(false);
    },
    renderOnboardImage(module) {
      switch (module) {
        case 'livechat':
          /*eslint-disable */
          return require('dashboard/assets/images/berrysupport-onboard-livechat.svg');
        case 'email':
          /*eslint-disable */
          return require('dashboard/assets/images/berrysupport-onboard-mail.svg');
        case 'sms':
          /*eslint-disable */
          return require('dashboard/assets/images/berrysupport-onboard-chat.svg');
        case 'social':
          /*eslint-disable */
          return require('dashboard/assets/images/berrysupport-onboard-social.svg');
        case 'agents':
          /*eslint-disable */
          return require('dashboard/assets/images/berrysupport-onboard-agents.svg');
        case 'campaigns':
          /*eslint-disable */
          return require('dashboard/assets/images/berrysupport-onboard-campaigns.svg');
        case 'canned':
          /*eslint-disable */
          return require('dashboard/assets/images/berrysupport-onboard-canned.svg');
        case 'notifications':
          /*eslint-disable */
          return require('dashboard/assets/images/berrysupport-onboard-notifications.svg');
        case 'completed':
          /*eslint-disable */
          return require('dashboard/assets/images/berrysupport-onboard-complete.svg');
        default:
          /*eslint-disable */
          return require('dashboard/assets/images/berrysupport-onboard-mail.svg');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-container {
  padding: var(--space-medium) var(--space-large) var(--space-large)
    var(--space-large);
  width: fit-content;
  & .berrysupport-onboard-wrapper {
    padding: var(--space-medium) var(--space-large) var(--space-large)
      var(--space-large);
    & .berrysupport-onboard-step-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      & .berrysupport-onboard-graphic-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      & .berrysupport-onboard-text-wrapper {
        & .berrysupport-onboarding-feature-checkmark {
          color: var(--success-color);
          margin-right: 0.2rem;
        }
      }
      & .berrysupport-onboard-graphic-wrapper,
      & .berrysupport-onboard-text-wrapper {
        width: 48%;
        & ul {
          list-style-type: none;
          margin-top: var(--space-normal);
          margin-left: 0;
        }
        & ul li {
          margin-bottom: var(--space-normal);
          font-size: var(--font-size-small);
        }
      }
    }
    & .modal-footer {
      justify-content: space-between;
      padding-top: var(--space-medium);
    }
  }
}
</style>
