/* eslint-disable */
<template>
  <div class="columns content-box">
    <button
      v-if="showAdd"
      class="button success block nice"
      :disabled="uiFlags.isCreating"
      @click="addWidgetToShopify"
    >
      <i v-if="uiFlags.isCreating" class="icon" />
      <spinner v-if="uiFlags.isCreating" />
      Add Widget To Store
    </button>

    <button
      v-if="showRemove"
      class="button alert nice"
      :disabled="uiFlags.isDeleting"
      @click="removeWidgetFromShopify"
    >
      <i v-if="uiFlags.isDeleting" class="icon" />
      <spinner v-if="uiFlags.isDeleting" />
      Remove Widget From Store
    </button>
  </div>
</template>

<script>
import configMixin from 'shared/mixins/configMixin';
import Spinner from 'shared/components/Spinner';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    Spinner,
  },
  mixins: [configMixin, alertMixin],
  props: {
    inboxId: {
      required: true,
    },
    showAdd: {
      type: Boolean,
    },
    showRemove: {
      type: Boolean,
    },
    onAdd: {
      type: Function,
    },
  },
  computed: {
    currentInbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      uiFlags: 'berrysupportScriptTag/getUIFlags',
    }),
  },
  methods: {
    async addWidgetToShopify() {
      const { website_token } = this.$store.getters['inboxes/getInbox'](
        this.inboxId
      );
      try {
        /* eslint-disable no-unused-expressions */
        await this.$store.dispatch('berrysupportScriptTag/create', {
          websiteToken: website_token,
        });
        this.showAlert('Chat widget has been added to your Shopify store.');
        this.onAdd && this.onAdd();
        return true;
      } catch (e) {
        this.showAlert(e.message);
      }
      return true;
    },
    async removeWidgetFromShopify() {
      const { website_token } = this.$store.getters['inboxes/getInbox'](
        this.inboxId
      );
      try {
        await this.$store.dispatch('berrysupportScriptTag/delete', {
          websiteToken: website_token,
        });
        this.showAlert('Chat widget has been removed from your Shopify store.');
        return true;
      } catch (e) {
        this.showAlert(e.message);
      }
      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
</style>
