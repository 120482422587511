/* eslint no-console: 0 */
/* eslint no-param-reassign: 0 */
/* eslint no-shadow: 0 */
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import BerrySupportScriptTagApi from '../../api/berrysupportScriptTag';
const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
    isCreatingError: false,
    isDeletingError: false,
  },
};

export const getters = {
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getScriptTag: $state => {
    return $state.records;
  },
};

export const actions = {
  create: async function createScriptTag({ commit }, { websiteToken = '' }) {
    commit(types.default.SET_BERRY_SUPPORT_SCRIPT_TAG_UI_FLAG, {
      isCreating: true,
      isCreatingError: false,
    });
    try {
      const response = await BerrySupportScriptTagApi.create({ websiteToken });
      commit(types.default.ADD_BERRY_SUPPORT_SCRIPT_TAG, response.data);
      commit(types.default.SET_BERRY_SUPPORT_SCRIPT_TAG_UI_FLAG, {
        isCreating: false,
        isCreatingError: false,
      });
      return response.data;
    } catch (error) {
      commit(types.default.SET_BERRY_SUPPORT_SCRIPT_TAG_UI_FLAG, {
        isCreating: false,
        isCreatingError: true,
      });
      throw new Error(error);
    } finally {
      commit(types.default.SET_BERRY_SUPPORT_SCRIPT_TAG_UI_FLAG, {
        isCreating: false,
      });
    }
  },
  delete: async function deleteScriptTag({ commit }, { websiteToken = '' }) {
    commit(types.default.SET_BERRY_SUPPORT_SCRIPT_TAG_UI_FLAG, {
      isDeleting: true,
      isDeletingError: false,
    });
    try {
      await BerrySupportScriptTagApi.delete(websiteToken);
      commit(types.default.DELETE_BERRY_SUPPORT_SCRIPT_TAG, websiteToken);
    } catch (error) {
      commit(types.default.SET_BERRY_SUPPORT_SCRIPT_TAG_UI_FLAG, {
        isDeleting: false,
        isDeletingError: true,
      });
      throw new Error(error);
    } finally {
      commit(types.default.SET_BERRY_SUPPORT_SCRIPT_TAG_UI_FLAG, {
        isDeleting: false,
        isDeletingError: false,
      });
    }
  },
};

export const mutations = {
  [types.default.SET_BERRY_SUPPORT_SCRIPT_TAG_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_BERRY_SUPPORT_SCRIPT_TAG]: MutationHelpers.set,
  [types.default.ADD_BERRY_SUPPORT_SCRIPT_TAG]: MutationHelpers.create,
  [types.default.EDIT_BERRY_SUPPORT_SCRIPT_TAG]: MutationHelpers.update,
  [types.default.DELETE_BERRY_SUPPORT_SCRIPT_TAG]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
