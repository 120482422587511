/* global axios */
import ShopApiClient from './ShopApiClient';
import BerrySupportCacheApi from './berrysupportCache';

class BerrySupportOrdersApi extends ShopApiClient {
  constructor() {
    super('orders', { accountScoped: false, apiVersion: 'v1' });
  }

  async getOrders({ email, phone_number, contactId, refresh }) {
    if (email || phone_number) {
      if (refresh) {
        await BerrySupportCacheApi.delete(
          `${this.apiVersion}/${this.resource}/${contactId}`
        );
      }
      return axios.get(`${this.url}/${contactId}`, {
        params: {
          email,
          phone_number,
        },
      });
    }
    return new Promise((res, rej) => {
      rej(
        new Error(
          'Please edit this contact and add an email or phone number to this contact to retrive orders.'
        )
      );
    });
  }
}

export default new BerrySupportOrdersApi();
