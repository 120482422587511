<template>
  <div class="berrysupport-accordion-wrapper">
    <div class="berrysupport-accordion--header">
      <a href="#" class="" @click.prevent="active = !active">
        <span>{{ title.replace(/ /g, '\u00a0') }}</span>
        <span v-show="!active" class="down-Arrow">&#9660;</span>
        <span v-show="active" class="up-Arrow">&#9650;</span>
      </a>
    </div>
    <div v-show="active" class="berrysupport-accordion-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: ['title'],
  data() {
    return {
      active: false,
    };
  },
};
</script>

<style scoped>
.icon {
  font-size: var(--font-size-large) !important;
}
.berrysupport-accordion-wrapper {
  border-radius: var(--border-radius-normal);
}
.berrysupport-accordion--header {
  padding: var(--space-normal);
  border-bottom: 1px solid var(--color-border);
  font-weight: bold;
}
.berrysupport-accordion--header a {
  display: flex;
  justify-content: space-between;
  color: var(--color-heading);
}
.berrysupport-accordion-content {
  padding: var(--space-slab);
  border-bottom: 1px solid var(--color-border);
}
</style>
